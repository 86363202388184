// (c) Copyright Ascensio System SIA 2009-2025
//
// This program is a free software product.
// You can redistribute it and/or modify it under the terms
// of the GNU Affero General Public License (AGPL) version 3 as published by the Free Software
// Foundation. In accordance with Section 7(a) of the GNU AGPL its Section 15 shall be amended
// to the effect that Ascensio System SIA expressly excludes the warranty of non-infringement of
// any third-party rights.
//
// This program is distributed WITHOUT ANY WARRANTY, without even the implied warranty
// of MERCHANTABILITY or FITNESS FOR A PARTICULAR  PURPOSE. For details, see
// the GNU AGPL at: http://www.gnu.org/licenses/agpl-3.0.html
//
// You can contact Ascensio System SIA at Lubanas st. 125a-25, Riga, Latvia, EU, LV-1021.
//
// The  interactive user interfaces in modified source and object code versions of the Program must
// display Appropriate Legal Notices, as required under Section 5 of the GNU AGPL version 3.
//
// Pursuant to Section 7(b) of the License you must retain the original Product logo when
// distributing the program. Pursuant to Section 7(e) we decline to grant you any rights under
// trademark law for use of our trademarks.
//
// All the Product's GUI elements, including illustrations and icon sets, as well as technical writing
// content are licensed under the terms of the Creative Commons Attribution-ShareAlike 4.0
// International. See the License terms at http://creativecommons.org/licenses/by-sa/4.0/legalcode
@import "../../styles/variables/index.scss";
@import "../../styles/variables/_colors.scss";
@import "../../styles/_mixins.scss";

.modal {
  #create-text-input::-webkit-search-decoration,
  #create-text-input::-webkit-search-cancel-button,
  #create-text-input::-webkit-search-results-button,
  #create-text-input::-webkit-search-results-decoration {
    appearance: none;
    -webkit-appearance: none;
  }

  pointer-events: none;
  &.modalActive {
    pointer-events: all;
  }

  .loaderWrapper {
    padding: 0 16px 16px;
  }
}

.dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 0 auto;
  min-height: 100%;
}

.content {
  box-sizing: border-box;
  position: relative;

  background-color: var(--modal-dialog-bg-color);
  color: var(--modal-dialog-text-color);
  padding: 0 0 -16px;

  &.displayTypeModal {
    padding: 0;
    height: auto;
    max-height: 280px;
    width: 400px;
    border-radius: 6px;

    &.large {
      max-height: 400px;
      width: 520px;
    }

    &.huge {
      max-width: 730px;
    }

    &.autoMaxHeight {
      max-height: unset;
    }

    &.autoMaxWidth {
      width: auto;
    }

    @include mobile {
      transform: translateY(100%);
      transition: transform 0.3s ease-in-out;
      position: absolute;
      bottom: 0;
      width: 100% !important;
      height: auto;
      border-radius: 6px 6px 0 0;

      &.visible {
        transform: translateY(0);
      }
    }
  }

  &.displayTypeAside {
    width: 480px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;

    inset-inline-end: 0;

    transform: translateX(var(--modal-dialog-content-transform-x));
    transition: transform 0.3s ease-in-out;

    &.visible {
      transform: translateX(0);
    }

    @include mobile {
      transform: translateY(100%);
      height: calc(100% - 64px);
      width: 100%;
      inset-inline: 0;
      top: auto;
      bottom: 0;

      &.visible {
        transform: translateY(0);
      }
    }
  }
}

.header {
  &.displayTypeModal {
    margin-bottom: 16px;
  }
}

.body {
  position: relative;
  padding: 0 16px;
  white-space: pre-line;

  :global(#modal-scroll > .scroll-wrapper > .scroller > .scroll-body) {
    margin-inline-end: 0 !important;
    padding-inline-end: 16px !important;
  }

  &.scrollLocked {
    :global(#modal-scroll > .scroll-wrapper > .scroller) {
      overflow: hidden !important;
    }

    :global(#modal-scroll > .scroll-wrapper > .scroller > .scroll-body) {
      margin-inline-end: 0 !important;
    }
  }

  &.displayTypeModal {
    padding-bottom: 16px;

    &.hasFooter {
      padding-bottom: 8px;
    }
  }

  &.displayTypeAside {
    margin-inline-end: 0;
    height: 100%;
    min-height: auto;

    &.withBodyScroll:not(&.withoutPadding) {
      margin-inline-end: -16px;
    }
  }

  &.withoutPadding {
    padding: 0;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 16px;
  gap: 8px;

  &.withFooterBorder {
    border-top: 1px solid var(--modal-dialog-header-border-color);
  }

  &.doubleFooterLine {
    flex-direction: column;

    div {
      display: flex;
      gap: 8px;
    }
  }

  @include tablet-and-below {
    gap: 10px;
  }
}

.formWrapper {
  display: contents;
}

.modalBackdrop {
  display: block;
  height: 100%;
  min-height: stretch;
  max-height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  // doesn't require mirroring for RTL
  left: 0;
  top: 0;

  background: var(--modal-dialog-backdrop-bg-color);
  transition: opacity 0.2s;
  opacity: 0;

  @include mobile {
    position: absolute;
  }

  &.modalBackdropActive {
    opacity: 1;
  }
}
