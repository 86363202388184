@import "./variables/index.scss";

@mixin common-text-styles {
  font-family: var(--text-font-family);
  color: var(--text-color);

  &[data-align="left"] {
    text-align: left;
  }

  &[data-align="right"] {
    text-align: right;
  }

  &[data-align="center"] {
    text-align: center;
  }

  &[data-truncate="true"] {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &[data-color] {
    color: attr(data-color);
  }
}
