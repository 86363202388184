// (c) Copyright Ascensio System SIA 2009-2025
//
// This program is a free software product.
// You can redistribute it and/or modify it under the terms
// of the GNU Affero General Public License (AGPL) version 3 as published by the Free Software
// Foundation. In accordance with Section 7(a) of the GNU AGPL its Section 15 shall be amended
// to the effect that Ascensio System SIA expressly excludes the warranty of non-infringement of
// any third-party rights.
//
// This program is distributed WITHOUT ANY WARRANTY, without even the implied warranty
// of MERCHANTABILITY or FITNESS FOR A PARTICULAR  PURPOSE. For details, see
// the GNU AGPL at: http://www.gnu.org/licenses/agpl-3.0.html
//
// You can contact Ascensio System SIA at Lubanas st. 125a-25, Riga, Latvia, EU, LV-1021.
//
// The  interactive user interfaces in modified source and object code versions of the Program must
// display Appropriate Legal Notices, as required under Section 5 of the GNU AGPL version 3.
//
// Pursuant to Section 7(b) of the License you must retain the original Product logo when
// distributing the program. Pursuant to Section 7(e) we decline to grant you any rights under
// trademark law for use of our trademarks.
//
// All the Product's GUI elements, including illustrations and icon sets, as well as technical writing
// content are licensed under the terms of the Creative Commons Attribution-ShareAlike 4.0
// International. See the License terms at http://creativecommons.org/licenses/by-sa/4.0/legalcode
@import "../../styles/variables/_colors.scss";

.label {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0;

  line-height: 16px;

  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: $tap-highlight;

  .checkbox {
    margin-inline-end: 12px;
    overflow: visible;
    outline: none;
  }

  .checkboxText {
    color: var(--text-color);
    margin-top: -2px;
  }

  svg {
    rect {
      fill: var(--checkbox-fill-color);
      stroke: var(--checkbox-border-color);
    }
    path {
      fill: var(--checkbox-arrow-color);
    }
    &:focus {
      outline: none;
      rect {
        stroke: var(--checkbox-focus-color);
      }
    }
  }

  &.error {
    .checkboxText {
      color: var(--checkbox-error-color);
    }

    svg {
      rect {
        stroke: var(--checkbox-error-color) !important;
      }
    }
  }

  &.indeterminate {
    svg {
      rect {
        fill: var(--checkbox-fill-color);
        stroke: var(--checkbox-border-color);
      }
      rect:last-child {
        fill: var(--checkbox-indeterminate-color);
        stroke: var(--checkbox-fill-color);
      }
    }
  }

  &.disabled {
    svg {
      rect {
        fill: var(--checkbox-disable-fill-color);
        stroke: var(--checkbox-disable-border-color);
      }
      path {
        fill: var(--checkbox-disable-arrow-color);
      }
    }

    .checkboxText {
      color: var(--text-disable-color);
    }
  }

  &.disabled.indeterminate {
    svg {
      rect {
        fill: var(--checkbox-disable-fill-color);
        stroke: var(--checkbox-disable-border-color);
      }
      rect:last-child {
        fill: var(--checkbox-disable-indeterminate-color);
      }
    }
  }

  &:hover {
    cursor: pointer;

    svg {
      rect:nth-child(1) {
        stroke: var(--checkbox-hover-border-color);
      }
    }

    &.disabled {
      cursor: not-allowed;
    }

    &.indeterminate {
      cursor: pointer;

      svg {
        rect:nth-child(1) {
          stroke: var(--checkbox-hover-border-color);
        }
        rect:last-child {
          fill: var(--checkbox-hover-indeterminate-color);
        }
      }
    }
  }

  &:active {
    cursor: pointer;

    svg {
      rect:nth-child(1) {
        stroke: var(--checkbox-pressed-border-color);
        fill: var(--checkbox-pressed-fill-color);
      }
    }

    &.disabled {
      cursor: not-allowed;
    }

    &.indeterminate {
      cursor: pointer;
      svg {
        rect:nth-child(1) {
          stroke: var(--checkbox-pressed-border-color);
          fill: var(--checkbox-pressed-fill-color);
        }
        rect:last-child {
          fill: var(--checkbox-hover-indeterminate-color);
        }
      }
    }
  }
}

.wrapper {
  display: inline-block;
}

.helpButton {
  display: inline-block;
  margin-inline-start: 4px;
}

.hiddenInput {
  opacity: 0.0001;
  position: absolute;
  inset-inline-end: 0;
  z-index: -1;
}
