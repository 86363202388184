@import "../../styles/mixins";
@import "../../styles/variables/devices";
@import "../../styles/variables/colors";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  background: var(--form-wrapper-background);
  box-shadow: var(--form-wrapper-box-shadow);
  border-radius: 12px;
  max-width: 320px;
  min-width: 320px;

  @include tablet-and-below {
    max-width: 416px;
    min-width: 416px;
  }

  @include mobile {
    padding: 0;
    border-radius: 0;
    box-shadow: none !important;
    max-width: 100%;
    min-width: 100%;
    background: transparent !important;
  }
}
