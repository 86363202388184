@import "../../styles/variables/index.scss";
@import "../../styles/_mixins.scss";

.styledInput {
  :global {
    .append {
      padding-right: 2px;
      right: -16px;
    }
  }

  &.rtlStyledInput {
    :global {
      .append {
        right: inherit;
        padding-right: 0;

        padding-left: 0px;
        left: 16px;
      }

      input {
        text-align: right;
      }
    }
  }
}

.tooltipContainer {
  color: var(--password-input-tooltip-text-color);

  :global {
    .generate-btn-container {
      margin-top: 10px;
    }
  }
}

.tooltip {
  width: 294px;

  @include mobile {
    width: 320px;
  }
}

.styledInput {
  display: flex;
  align-items: center;
  line-height: 32px;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;

  &.fullWidth {
    display: block;
  }

  input {
    flex: inherit;
    width: calc(100% - 40px) !important;
    &::-ms-reveal {
      display: none;
    }
  }

  @include tablet-and-below {
    flex-wrap: wrap;
  }

  :global {
    .input-block-icon {
      height: 42px;
    }

    .append {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);

      background: unset !important;
    }

    .prepend-children {
      padding: 0;
    }

    .break {
      flex-basis: 100%;
      height: 0;
    }

    .text-tooltip {
      line-height: 14px;
      margin-top: -2px;
    }

    .password-field-wrapper {
      display: flex;
      width: auto;

      @include mobile {
        width: 100%;
      }
    }
  }

  :global {
    .input-relative {
      svg {
        path {
          fill: var(--password-input-icon-color) !important;
        }
      }

      &:hover {
        svg {
          path {
            fill: var(--password-input-icon-color-hover) !important;
          }
        }
      }
    }
  }

  &[data-disabled="true"] {
    :global {
      .input-relative {
        svg {
          path {
            fill: var(--password-input-icon-color-disabled);
          }
        }
      }
    }
  }
}

.passwordProgress {
  &.withInputWidth {
    width: var(--input-width);
  }

  &:not(.withInputWidth) {
    flex: auto;
  }

  :global {
    .input-relative {
      position: relative;
      svg {
        overflow: hidden;
        vertical-align: middle;
      }
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
}
