@import "../../styles/variables/colors";

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 203;
  display: none;
  height: 100vh;
  width: 100vw;

  &.visible {
    display: block;
  }

  &.withBackground {
    background-color: var(--backdrop-background-color);
  }

  &.withoutBackground {
    background-color: unset;
  }
}
