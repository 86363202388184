// (c) Copyright Ascensio System SIA 2009-2025
//
// This program is a free software product.
// You can redistribute it and/or modify it under the terms
// of the GNU Affero General Public License (AGPL) version 3 as published by the Free Software
// Foundation. In accordance with Section 7(a) of the GNU AGPL its Section 15 shall be amended
// to the effect that Ascensio System SIA expressly excludes the warranty of non-infringement of
// any third-party rights.
//
// This program is distributed WITHOUT ANY WARRANTY, without even the implied warranty
// of MERCHANTABILITY or FITNESS FOR A PARTICULAR  PURPOSE. For details, see
// the GNU AGPL at: http://www.gnu.org/licenses/agpl-3.0.html
//
// You can contact Ascensio System SIA at Lubanas st. 125a-25, Riga, Latvia, EU, LV-1021.
//
// The  interactive user interfaces in modified source and object code versions of the Program must
// display Appropriate Legal Notices, as required under Section 5 of the GNU AGPL version 3.
//
// Pursuant to Section 7(b) of the License you must retain the original Product logo when
// distributing the program. Pursuant to Section 7(e) we decline to grant you any rights under
// trademark law for use of our trademarks.
//
// All the Product's GUI elements, including illustrations and icon sets, as well as technical writing
// content are licensed under the terms of the Creative Commons Attribution-ShareAlike 4.0
// International. See the License terms at http://creativecommons.org/licenses/by-sa/4.0/legalcode

@import "../../styles/variables/index.scss";
@import "../../styles/variables/_colors.scss";
@import "../../styles/_mixins.scss";

.providerRow {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding: 8px 0;

  display: grid;
  grid-template-columns: 32px minmax(100px, 501px) 100px;

  svg {
    height: 24px;
    width: 24px;
    padding-inline-start: 4px;

    :global(.dark) & {
      path {
        fill: #{$white};
      }
    }
  }

  .providerName {
    overflow-wrap: break-word;
    padding: 0 12px;
    line-height: 16px;
  }
}
