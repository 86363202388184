// (c) Copyright Ascensio System SIA 2009-2025
//
// This program is a free software product.
// You can redistribute it and/or modify it under the terms
// of the GNU Affero General Public License (AGPL) version 3 as published by the Free Software
// Foundation. In accordance with Section 7(a) of the GNU AGPL its Section 15 shall be amended
// to the effect that Ascensio System SIA expressly excludes the warranty of non-infringement of
// any third-party rights.
//
// This program is distributed WITHOUT ANY WARRANTY, without even the implied warranty
// of MERCHANTABILITY or FITNESS FOR A PARTICULAR  PURPOSE. For details, see
// the GNU AGPL at: http://www.gnu.org/licenses/agpl-3.0.html
//
// You can contact Ascensio System SIA at Lubanas st. 125a-25, Riga, Latvia, EU, LV-1021.
//
// The  interactive user interfaces in modified source and object code versions of the Program must
// display Appropriate Legal Notices, as required under Section 5 of the GNU AGPL version 3.
//
// Pursuant to Section 7(b) of the License you must retain the original Product logo when
// distributing the program. Pursuant to Section 7(e) we decline to grant you any rights under
// trademark law for use of our trademarks.
//
// All the Product's GUI elements, including illustrations and icon sets, as well as technical writing
// content are licensed under the terms of the Creative Commons Attribution-ShareAlike 4.0
// International. See the License terms at http://creativecommons.org/licenses/by-sa/4.0/legalcode

@import "../../styles/variables/index.scss";
@import "../../styles/variables/_colors.scss";
@import "../../styles/_mixins.scss";

:global(.light) {
  :local {
    .label.checked.disabled svg {
      rect {
        fill: var(
          --color-scheme-main-accent,
          --toggle-button-fill-color-default
        ) !important;
      }
      circle {
        fill: $white;
      }
    }
  }
}

:global(.rtl) {
  :local {
    .label svg {
      transform: scaleX(-1);
    }
  }
}

.container {
  display: inline-block;
}

.label {
  position: absolute;
  -webkit-appearance: none;
  align-items: start;
  outline: none;
  -webkit-tap-highlight-color: $tap-highlight;

  @include no-user-select;

  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 8px;

  cursor: pointer;

  svg {
    rect {
      fill: var(--toggle-button-fill-color-off);
    }

    circle {
      fill: var(--toggle-button-fill-circle-color-off);
    }

    &:hover {
      rect {
        fill: var(--toggle-button-hover-fill-color-off);
      }
    }

    &:active {
      rect {
        fill: var(--toggle-button-fill-color-off);
      }
    }
  }

  .toggleButtonText {
    color: var(--toggle-button-text-color);
  }

  &.checked {
    svg {
      rect {
        fill: var(
          --color-scheme-main-accent,
          --toggle-button-fill-color-default
        ) !important;

        &:hover {
          opacity: 0.85;
        }
      }

      circle {
        fill: var(
          --color-scheme-text-accent,
          --toggle-button-fill-circle-color
        );
      }
    }
  }

  &.disabled {
    pointer-events: none;
    cursor: default;

    svg {
      opacity: 0.6;

      circle {
        opacity: 0.6;
      }
    }

    .toggleButtonText {
      color: var(--toggle-button-text-disable-color);
    }
  }

  &.checked.disabled {
    svg {
      opacity: 0.6;

      rect {
        fill: var(
          --color-scheme-main-accent,
          --toggle-button-fill-color-default
        ) !important;
      }

      circle {
        fill: var(--toggle-button-fill-circle-color-off);
        opacity: 0.6;
      }
    }
  }
}

.hiddenInput {
  opacity: 0.0001;
  position: absolute;
  inset-inline-end: 0;
  z-index: -1;
}
