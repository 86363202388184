$white: #ffffff;
$black: #333333;
$dark-black: #000;

$gray-light: #f8f9f9;
$dark-gray-light: #282828;
$light-gray-hover: #f3f4f4;
$light-dark-gray-hover: #3d3d3d;
$gray-light-mid: #eceef1;
$gray-dark-mid: #242424;
$light-gray-selected: #dfe2e3;
$gray-strong: #d0d5da;
$gray-dark-strong: #474747;
$gray: #a3a9ae;
$gray-dark: #858585;
$light-gray-dark: #657077;
$dark-gray-dark: #adadad;
$gray-text: #555f65;
$gray-dark-text: #5c5c5c;

$light-blue-main: #4781d1;
$light-blue-main-hover: rgba(71, 129, 209, 0.85);
$light-blue-main-disabled: rgba(71, 129, 209, 0.6);
$light-blue-main-pressed: #4074bc;
$light-second-main: #5299e0;
$light-second-main-hover: rgba(82, 153, 224, 0.85);
$light-second-main-disabled: rgba(82, 153, 224, 0.6);
$light-blue-action: #e4ecf8;
$dark-grey-action: #515151;
$light-blue-hover: #bed3ef;
$dark-grey-hover: #7b7b7b;

$light-active: #f2f6fc;
$dark-active: #414141;

$main-green: #2db482;
$second-green: #22c386;
$second-green-dark: #2e5e4c;
$main-orange: #f97a0b;
$second-orange: #ff9933;
$main-red: #f2675a;
$main-purple: #533ed1;
$purple: #6d4ec2;
$second-purple: #8570bd;
$second-purple-dark: #544c6a;
$main-blue-light: #13b7ec;
$second-blue: #11a3d4;
$main-yellow: #ffd30f;

$blur-light: rgba(6, 22, 38, 0.2);
$blur-dark: rgba(27, 27, 27, 0.6);

$light-error-status: #f24724;
$dark-error-status: #e06451;
$favorites-status: #edc409;
$favorite-status-dark: #a38a1a;
$light-status-warning: #ed7309;
$dark-status-warning: #e17415;
$light-status-positive: #35ad17;
$dark-status-positive: #3ba420;

$light-icons: #3b72a7;
$dark-icon: #e6e6e6;
$link: #316daa;
$dark-link: #e06a1b;
$blue-light-mid: #265a8f;
$big-gray-dark-mid: #b5b5b5;

$light-toast-done: #e3f7be;
$dark-toast-done: #9de051;
$light-toast-info: #f8f7bf;
$dark-toast-info: #e0d751;
$light-toast-alert: #f7e6be;
$dark-toast-alert: #e0b151;
$light-toast-warning: #f7cdbe;
$dark-toast-warning: #e07751;

$loader: rgba(0, 0, 0, 0.05);
$dnd-color: rgba(229, 228, 138, 0.5);
$dnd-dark-color: rgba(229, 228, 138, 0.12);
$dnd-hover-color: rgba(204, 202, 102, 0.5);
$dnd-dark-hover: rgba(204, 184, 102, 0.2);
$on-white-color: rgba(0, 0, 0, 0.1);
$on-black-color: rgba(255, 255, 255, 0.1);
$box-shadow-color: #040f1b14;
$box-shadow-dark-color: #040f1b29;
$loader-light: #e7e7e7;
$loader-dark: #484848;

$editor-green-color: #40865c;
$editor-blue-color: #446995;
$editor-orange-color: #b26a54;
$editor-red-color: #aa5251;
$windows-color: #4c83cd;
$linux-color: #f4bb40;
$android-color: #a8d548;
$moonstone: #35a5d2;

$tick-color: #20d21f;
$red-romb: #ff6f3d;
$blue-romb: #5dc0e8;
$green-romb: #95c038;
$tap-highlight: rgba(0, 0, 0, 0);
$badge-shadow: rgba(4, 15, 27, 0.16);
$popup-shadow: rgba(4, 15, 27, 0.08);
$menu-shadow: rgba(4, 15, 27, 0.04);

$light-scroll: rgba(6, 22, 38, 0.16);
$light-scroll-hover: rgba(6, 22, 38, 0.32);
$light-scroll-active: rgba(6, 22, 38, 0.5);
$dark-scroll: rgba(136, 136, 136, 0.4);
$dark-scroll-hover: rgba(136, 136, 136, 0.64);
$dark-scroll-active: rgba(136, 136, 136, 0.8);

$silver: rgba(200, 200, 200, 0.2);
$highland-gray: rgba(11, 11, 11, 0.7);
$alpha-green-light: rgba(53, 173, 23, 0.1);
$alpha-green-dark: rgba(59, 164, 32, 0.1);
$alpha-red-light: rgba(242, 28, 14, 0.1);
$alpha-red-dark: rgba(224, 100, 81, 0.1);

$background-color: rgba(0, 0, 0, 0.8);
$dark-icon-color: rgba(255, 255, 255, 0.6);
$brown-background: rgba(0, 0, 0, 0.4);
$player-speed-control-background: rgba(51, 51, 51, 0.65);
$viewer-dark-color: rgba(55, 55, 55, 0.6);
$error-bg-color: rgba(0, 0, 0, 0.7);
$media-viewer-dark: rgba(0, 0, 0, 0.6);

$player-timeline-hover: rgba(255, 255, 255, 0.5);
$player-timeline-progress: rgba(255, 255, 255, 0.4);
$player-background: rgba(255, 255, 255, 0.3);

$viewer-player-controls-gradient: rgba(0, 0, 0, 0.64);
$viewer-player-controls-gradient-end-color: rgba(0, 0, 0, 0.89);

$logo-colors: (
  #ff6680,
  #ff8f40,
  #f2d230,
  #61c059,
  #1fcecb,
  #5cc3f7,
  #6191f2,
  #7757d9,
  #ff7fd4
);
