// (c) Copyright Ascensio System SIA 2009-2025
//
// This program is a free software product.
// You can redistribute it and/or modify it under the terms
// of the GNU Affero General Public License (AGPL) version 3 as published by the Free Software
// Foundation. In accordance with Section 7(a) of the GNU AGPL its Section 15 shall be amended
// to the effect that Ascensio System SIA expressly excludes the warranty of non-infringement of
// any third-party rights.
//
// This program is distributed WITHOUT ANY WARRANTY, without even the implied warranty
// of MERCHANTABILITY or FITNESS FOR A PARTICULAR  PURPOSE. For details, see
// the GNU AGPL at: http://www.gnu.org/licenses/agpl-3.0.html
//
// You can contact Ascensio System SIA at Lubanas st. 125a-25, Riga, Latvia, EU, LV-1021.
//
// The  interactive user interfaces in modified source and object code versions of the Program must
// display Appropriate Legal Notices, as required under Section 5 of the GNU AGPL version 3.
//
// Pursuant to Section 7(b) of the License you must retain the original Product logo when
// distributing the program. Pursuant to Section 7(e) we decline to grant you any rights under
// trademark law for use of our trademarks.
//
// All the Product's GUI elements, including illustrations and icon sets, as well as technical writing
// content are licensed under the terms of the Creative Commons Attribution-ShareAlike 4.0
// International. See the License terms at http://creativecommons.org/licenses/by-sa/4.0/legalcode

@import "../../styles/variables/_colors";
@import "../../styles/variables/devices.scss";
@import "../../styles/_mixins";

.elementWrapper {
  display: flex;
  align-items: center;
  margin-inline-start: auto;
}

.iconWrapper {
  display: flex;
  align-items: center;
  width: 16px;
  margin-inline-end: 8px;
  height: 20px;

  div {
    height: 16px;
  }

  svg {
    &:not(:root) {
      width: 100%;
      height: 100%;
    }
  }
  img {
    width: 100%;
    max-width: 16px;
    height: 100%;
    max-height: 16px;
    margin-top: 12px;
  }
}

.wrapperBadge {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-inline-start: auto;
}

.wrapperToggle {
  display: flex;
  align-items: center;
  margin-inline-start: auto;
  width: 36px;

  & label {
    position: static;
  }
}

.dropDownItemIcon {
  svg {
    path[fill] {
      fill: var(--drop-down-item-icon-color);
    }
    path[stroke] {
      stroke: var(--drop-down-item-icon-color);
    }
    circle[fill] {
      fill: var(--drop-down-item-icon-color);
    }
    rect[fill] {
      fill: var(--drop-down-item-icon-color);
    }
  }

  &.disabled {
    svg {
      path[fill] {
        fill: var(--drop-down-item-icon-color-disabled);
      }
      path[stroke] {
        stroke: var(--drop-down-item-icon-color-disabled);
      }
      circle[fill] {
        fill: var(--drop-down-item-icon-color-disabled);
      }
      rect[fill] {
        fill: var(--drop-down-item-icon-color-disabled);
      }
    }
  }
}

.submenuArrow {
  width: 12px;
  height: 12px;
  margin-inline-end: 0;
  align-self: center;
  line-height: normal;
  margin-inline-start: auto;

  &.RTL {
    transform-box: content-box;
    transform: scaleX(-1);
  }

  &.active {
    transform: rotate(90deg);
    height: auto;
  }

  :global(.drop-down-item_icon) {
    height: 12px;
  }
}

.dropDownItem {
  @include truncate;

  display: flex;
  width: 100%;
  max-width: 100%;
  min-width: var(--drop-down-min-width, unset);

  border: 0px;
  cursor: pointer;

  margin: 0px;
  padding: 0 12px;
  box-sizing: border-box;
  text-decoration: none;
  user-select: none;
  outline: 0 !important;

  line-height: 32px;
  font-size: 13px;
  font-weight: 600;
  color: var(--drop-down-item-icon-color);

  -webkit-tap-highlight-color: #{$tap-highlight};
  text-transform: none;

  &:hover:not(.separator) {
    background-color: var(--drop-down-item-hover-color);
  }

  &:active:not(.separator) {
    background-color: var(--drop-down-item-hover-color);
  }

  @include tablet-and-below {
    line-height: 36px;
    padding: 0 16px;
  }
}

.separator {
  padding: 0 16px;
  background-color: var(--dropdown-item-separator-border);
  cursor: default;
  margin: 4px 16px 4px;
  line-height: 1px;
  height: 1px;
  width: calc(100% - 32px);

  &:hover {
    cursor: default;
  }
}

.headerItem {
  align-items: center;
  height: 48px;
  padding: 13px 16px 18.2px;
  margin: 0 0 6px;
  border-bottom: 1px solid var(--dropdown-item-separator-border);
  font-size: 15px;
  font-weight: 600;
  line-height: 16px !important;
  cursor: default;

  &:hover {
    cursor: default;
    background-color: unset;
  }

  &:active {
    cursor: default;
    background-color: unset;
  }
}

.noHover {
  &:hover {
    background-color: unset !important;
  }
}

.noActive {
  &:active {
    background-color: unset !important;
  }
}

.rtlItem {
  &:hover {
    text-align: right;
  }
}

.modern {
  padding: 0 8px;
}

.textOverflow {
  display: block;
}

.activeDescendant {
  background-color: var(--drop-down-item-hover-color);
}

.disabled {
  color: var(--dropdown-item-disabled-color);

  &:hover {
    cursor: default;
    background-color: unset !important;
  }
}

.selected {
  background-color: var(--dropdown-item-selected-color);
}
