// (c) Copyright Ascensio System SIA 2009-2025
//
// This program is a free software product.
// You can redistribute it and/or modify it under the terms
// of the GNU Affero General Public License (AGPL) version 3 as published by the Free Software
// Foundation. In accordance with Section 7(a) of the GNU AGPL its Section 15 shall be amended
// to the effect that Ascensio System SIA expressly excludes the warranty of non-infringement of
// any third-party rights.
//
// This program is distributed WITHOUT ANY WARRANTY, without even the implied warranty
// of MERCHANTABILITY or FITNESS FOR A PARTICULAR  PURPOSE. For details, see
// the GNU AGPL at: http://www.gnu.org/licenses/agpl-3.0.html
//
// You can contact Ascensio System SIA at Lubanas st. 125a-25, Riga, Latvia, EU, LV-1021.
//
// The  interactive user interfaces in modified source and object code versions of the Program must
// display Appropriate Legal Notices, as required under Section 5 of the GNU AGPL version 3.
//
// Pursuant to Section 7(b) of the License you must retain the original Product logo when
// distributing the program. Pursuant to Section 7(e) we decline to grant you any rights under
// trademark law for use of our trademarks.
//
// All the Product's GUI elements, including illustrations and icon sets, as well as technical writing
// content are licensed under the terms of the Creative Commons Attribution-ShareAlike 4.0
// International. See the License terms at http://creativecommons.org/licenses/by-sa/4.0/legalcode

@import "../../styles/variables/_colors.scss";

.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border-radius: 3px;
  cursor: pointer;
  margin: 0;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  padding-right: 4px;
  stroke: none;

  &:focus {
    outline: none;
  }

  // Size variants
  &.extraSmall {
    height: 24px;
    font-size: 12px;
    line-height: 15px;
    padding: 0 11.5px;
  }

  &.small {
    height: 32px;
    font-size: 13px;
    line-height: 20px;
    padding: 0 28px;
  }

  &.normal {
    height: 40px;
    font-size: 14px;
    line-height: 16px;
    padding: 0 28px;
  }

  &.medium {
    height: 44px;
    font-size: 16px;
    line-height: 22px;
    padding: 0 32px;
  }

  // Default variant
  background: var(--button-background-base);
  color: var(--button-color-base);
  border: var(--button-border-base);

  &:hover:not(:disabled) {
    background: var(--button-background-base-hover);
    color: var(--button-color-base-hover);
    border: var(--button-border-base-hover);
  }

  &:active:not(:disabled) {
    background: var(--button-background-base-active);
    color: var(--button-color-base-active);
    border: var(--button-border-base-active);
  }

  &:disabled {
    background: var(--button-background-base-disabled);
    color: var(--button-color-base-disabled);
    border: var(--button-border-base-disabled);
  }

  // Primary variant
  &.primary {
    background: var(--button-background-primary);
    color: var(--button-color-primary);
    border: var(--button-border-primary);
    opacity: 1;

    &:hover:not(:disabled) {
      background: var(--button-background-primary-hover);
      color: var(--button-color-primary-hover);
      opacity: 0.85;
    }

    &:active:not(:disabled) {
      background: var(--accent-button, --button-background-primary-active);
      color: var(--button-color-primary-active);
      border-color: var(--button-border-primary-active);

      filter: var(--button-brightness);
      opacity: 1;
    }

    &:disabled {
      background: var(--button-background-primary-disabled);
      color: var(--button-color-primary-disabled);
      border: var(--button-border-primary-disabled);
      opacity: 0.6;
    }
  }

  // Disabled state
  &:disabled {
    cursor: default;
  }

  // Scale (full width)
  &.scale {
    width: 100%;
  }

  // Content layout
  .buttonContent {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    height: 100%;

    position: relative;

    &.loading {
      visibility: hidden;
    }
  }

  .icon {
    display: flex;
    align-items: center;
  }

  // Loader
  .loader {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;

    width: 100%;
    height: 100%;

    svg {
      opacity: 1;
      color: var(--accent-button);
    }

    &.primary {
      svg {
        color: var(--button-loader-primary) !important;
      }
    }
  }

  &.isHovered {
    background-color: var(--button-background-base-hover);
    color: var(--button-color-base-hover);
    border: var(--button-border-base-hover);

    &.primary {
      background-color: var(--button-background-primary-hover);
      color: var(--button-color-primary-hover);
      border: none;
    }
  }

  &.isClicked {
    background-color: var(--button-background-base-active);
    color: var(--button-color-base-active);

    &.primary {
      background: var(--button-background-primary-active);
      color: var(--button-color-primary-active);
      filter: brightness(90%);
    }
  }

  &.isDisabled {
    cursor: default;
    pointer-events: none;
    background-color: var(--button-background-base-disabled);
    color: var(--button-color-base-disabled);
    border-color: var(--button-border-base-disabled);

    &.primary {
      background: var(--accent-button, --button-background-primary-disabled);
      color: var(--button-color-primary-disabled);
      border: var(--button-border-primary-disabled);
    }

    .loader {
      svg {
        opacity: 1;
      }
    }
  }
}
